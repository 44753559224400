@charset "utf-8";
$primary: #226699;
$success: #52CC14;
$navbar-item-hover-color: $primary;
// Set your brand colors
// Update Bulma's global variables

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;600&display=swap');
$family-sans-serif: "Fira Sans", sans-serif;

$family-primary: $family-sans-serif;
// Update some of Bulma's component variables

//header
.navbar-menu .navbar-item {
    opacity: .95;
}

//modal
$modal-background-background-color: rgba(1, 1, 1, 0.60);
$modal-card-head-padding: 20px;
$modal-card-title-color: $primary;

//tabs
$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;

@import "~bulma/bulma";
@import "~flatpickr/dist/themes/material_blue.css";
@import "~bulma-extensions/bulma-checkradio/src/sass/index";

.hoverable:hover {
    background-color: rgba(#226699, 0.05);
}

.settingsBoxRight {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

@media screen and (max-width: 1023px) {
    .app-root > .container:nth-child(2) {
        padding-left: .75rem;
        padding-right: .75rem;
    }
}

//day-plan-fact-box
.day-plan-fact-box {
    font-size: 1rem;
    min-width: 3em;
}
.day-plan-fact-box-empty {
    padding: 3em 0;
}

@media only screen and (min-width: 1216px) and (max-width: 1408px) {
    .day-plan-fact-box {
        font-size: .8rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1216px) {
    .day-plan-fact-box {
        font-size: .6rem;
    }
}

@media only screen and (max-width: 1024px) {
    .day-plan-fact-box {
        font-size: .7rem;
    }
}
